/**
 * Schedules and performs a weekly cleanup of specific localStorage keys on Sundays.
 * @param {Array<string>} keys - Array of keys to clear from localStorage.
 */
function scheduleLocalStorageClear(keys) {
    const today = new Date();
    const isSunday = today.getDay() === 0;

    // Marker to track when the cleanup last ran
    const lastCleared = localStorage.getItem('lastCleared');
    const lastClearedDate = lastCleared ? new Date(lastCleared) : null;

    // Only clear localStorage if today is Sunday and it hasn't already cleared today
    if (isSunday && (!lastClearedDate || lastClearedDate.toDateString() !== today.toDateString())) {

        keys.forEach((key) => {
            if (localStorage.getItem(key)) {
                localStorage.removeItem(key);
                console.log(`Cleared ${key} from localStorage.`);
            }
        });

        // Update the last cleared marker
        localStorage.setItem('lastCleared', today.toISOString());
        window.location.reload();
    }
}

// Register the cleanup function only once with an interval
export function initializeLocalStorageClear(keys) {
    // Immediately check and run the cleanup function
    scheduleLocalStorageClear(keys);

    // Set a weekly interval to check every 24 hours
    const interval = 24 * 60 * 60 * 1000; // Check daily
    setInterval(() => scheduleLocalStorageClear(keys), interval);
}