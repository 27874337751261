import "antd/dist/antd.min.css";

import AllRoutes from "./routes";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import socket from "services/socketServices";
import "react-loading-skeleton/dist/skeleton.css";
import LandingScreen from "./screens/landing";
import {LoadedContext} from "context/LoadedContext";
import useData from "hooks/useData";
import {GlobalStatesContext} from "context/GlobalStatesContext";
import CountDown from "components/CountDown";
import 'moment-timezone';
import { initializeLocalStorageClear } from './utils/localStorage';

import {
    isCurrentTimeInRange,
    isTransectionUnderProcess,
    not,
    saveAppVersion,
    updateToLatestVersion
} from "utils/helper";
import "./App.css";
import {getlinklyStatus} from "services/apis";

import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import usePOS from "hooks/usePOS";
import {useLocation, useNavigate} from "react-router-dom";
import useShortcuts from "hooks/useShortcuts";
import {useAdminTransection} from "hooks/admin/useAdminTransection";
import {Overlay} from "styles/brandingStyles";
import useNavigation from "hooks/useNavigation";
import useApiConfig from "hooks/useApiConfig";
import useAdmin from "hooks/admin/useAdmin";
import moment from 'moment';
import Waiting from "./screens/instructions/waiting";

function App() {


    // Remove the Transaction each sunday from localStorage.
    const keysToClear = ['transections'];
    useEffect(() => {
        // Initialize the cleanup function at app load
        initializeLocalStorageClear(keysToClear);
    }, []);

    const FIVE_HOUR = 18000000;
    const {loaded, setLoaded, setLanding, landing} = useContext(LoadedContext);
    const {linklyEndpoint} = useApiConfig();
    const {setGlobalState, globalState} = useContext(GlobalStatesContext);
    const {getCurrentPath} = useNavigation()

    const {
        getToken,
        secret,
        token,
        lastSessionId,
        enterRecoveryMode,
        logon,
        tokenExpiry,
        transactionStarted,
        setTransactionStarted,
    } = usePOS();
    const [requestPending, setRequestPending] = useState(false);

    const {catagorisData} = useData();
    const [isActive, setIsActive] = useState(true);
    const {enableCountdown, setEnableCountdown} = useContext(GlobalStatesContext);
    const navigate = useNavigate();
    const location = useLocation();

    const lastSessionIdRef = useRef(lastSessionId);
    const tokenRef = useRef(token);
    const shortCuts = useShortcuts();

    useEffect(() => {
        lastSessionIdRef.current = lastSessionId;
        tokenRef.current = token;
    }, [lastSessionId, token]);

    /** After each 5 Hours Auth Token is refreshing... */

    const isPOSFree = async (lastSessionId, token) => {
        if (!lastSessionId) {
            return true;
        } else {
            const res = await getlinklyStatus(linklyEndpoint, lastSessionId, token);
            return res?.status === 200 || res.status === 404 || res.status === 400;
        }
    };

    const refreshTheOuthToken = (secret) => {
        if (secret) {
            getToken(secret);
        }
    };

    const checkPOSStatus = async () => {
        const posFree = await isPOSFree(lastSessionIdRef.current, tokenRef.current);
        if (posFree) {
            refreshTheOuthToken(secret);
        } else {
            setTimeout(checkPOSStatus, 30000); // Retry after 30 Seconds if POS is not free
        }
    };

    // console.log(lastSessionId, " lastSessionId");
    // console.log(token, " token ");

    useEffect(() => {
        if (secret) {
            setInterval(checkPOSStatus, FIVE_HOUR); // Initial check after 100 seconds
        }
    }, [secret]);

    // useEffect(() => {
    //   socket.connect();
    //
    //   // Wait for the socket to connect before accessing its ID
    //   socket.on("connect", () => {
    //     setGlobalState((prev) => {
    //       return { ...prev, socketID: socket.id };
    //     });
    //   });
    //
    //   // Cleanup when the component unmounts
    //   return () => {
    //     socket.disconnect();
    //   };
    // }, []);


    // useEffect(() => {
    //     socket.connect();
    //
    //     // Wait for the socket to connect before accessing its ID
    //     socket.on("connect", () => {
    //         setGlobalState((prev) => {
    //             return {...prev, mySocketId: localStorage.getItem("mySocketId")}; // use custom ID rather than socket.id
    //         });
    //     });
    //
    //     return () => {
    //         socket.disconnect();
    //     };
    // }, []);


    useEffect(() => {
        if (!socket || !socket.connect) {
            console.log("Socket is not defined.");
            return;
        }

        socket.connect();
        // Wait for the socket to connect before accessing its ID
        socket.on("connect", () => {
            console.log(socket, 'socket....')

            if (socket.io.opts.query && socket.io.opts.query.id) {
                // setMySocketId(socket.query.id);
                setGlobalState((prev) => ({...prev, mySocketId: socket.io.opts.query.id}));
            }
        });

        return () => {
            if (socket && socket.disconnect) {
                socket.disconnect();
            }
        };
    }, [socket, setGlobalState]);

    useEffect(() => {
        setLanding(true); // Show landing page
    }, []);

    // Update the app to latest version if available.!
    useEffect(() => {
        updateToLatestVersion();
    }, []);


    useEffect(() => {
        socket.addEventListener("versionWebhook", (version) => {
            console.log(version, 'newVersion');
            saveAppVersion(version);
            const timeNow = moment();

            const startTime = localStorage.getItem('startTime');
            const endTime = localStorage.getItem('endTime');

            let delayBeforeTrigger = 0;
            const inRange = isCurrentTimeInRange(startTime, endTime);

            if (!inRange) {
                if (timeNow.isBefore(moment(startTime, 'HH:mm'))) {
                    // If the current time is before the start time.
                    delayBeforeTrigger = moment(startTime, 'HH:mm').diff(timeNow);
                } else if (timeNow.isAfter(moment(endTime, 'HH:mm'))) {
                    // If the current time is after the end time.
                    delayBeforeTrigger = moment(startTime, 'HH:mm').add(1, 'days').diff(timeNow);
                }
            }

            setTimeout(() => updateToLatestVersion(() => window.location.reload()), delayBeforeTrigger);
        });

        return () => {
            socket.removeListener("versionWebhook");
        };
    }, [socket, setGlobalState]);


    const handelSkipClose = () => {
        setLanding(false); // Close landing page
    };

    const inactivityTimer = useRef();

    const restartApp = useCallback(() => {
        setIsActive(false);
        setEnableCountdown(true);
        // Add logic here to restart your app as needed
        // For example, you can redirect to the home page or reload the app.
    }, [setIsActive, setEnableCountdown]);

    const handleMouseMove = ({target: {id} = {}} = {}) => {

        clearTimeout(inactivityTimer.current); // Clear the previous timer
        setEnableCountdown(false);

        // Start a new timer for inactivity
        inactivityTimer.current = setTimeout(restartApp, 60_000 * 2); // 1 minute (60,000 milliseconds)

        localStorage.setItem('timeoutID', inactivityTimer.current);

    };

    useEffect(() => {
        // Add an event listener for mousemove
        if (loaded) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("touchend", handleMouseMove);
        }

        // Clean up event listener and timer when the component unmounts
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener('touchend', handleMouseMove);
        };
    }, [loaded, landing, location.pathname]);

    useEffect(() => {
        if (isTransectionUnderProcess()) {
            navigate("/instructions");
        }
    }, [token, secret]);

    /** Call logon request if auth token availabe and valid */
    /**
     * The function `handleLogonRequest` is an asynchronous function that handles a logon request, checking
     * for the presence of a secret and token, and navigating to different routes based on the success or
     * failure of the logon process.
     */
        // This function handles the logon request, which typically involves authentication.
    const handleLogonRequest = async () => {
            // Check if necessary information (secret, token) is available and no other request is pending.
            if (secret && token && not(requestPending)) {
                // Initialize timeoutID to manage asynchronous delays.
                let timeoutID = null;

                try {
                    // Set the request as pending to prevent concurrent requests.
                    setRequestPending(true);

                    // Attempt to perform the logon (authentication) asynchronously.
                    await logon();

                    // Clear any existing timeout as the request was successful.
                    clearTimeout(timeoutID);
                    // If Admin is not opned then move to the root
                    if (!location.pathname.includes("/admin")) {
                        // If successful, navigate to the home page ("/").
                        // navigate("/");
                    }
                } catch (err) {
                    // Clear the timeout, as the logon attempt failed.
                    clearTimeout(timeoutID);

                    // Set a timeout to retry the logon after a delay (50 seconds in this case).
                    timeoutID = setTimeout(() => {
                        handleLogonRequest();
                    }, 50000);

                    // Navigate to a specific page indicating that the order is incomplete ("order_incomplete").
                    navigate("/order_incomplete"); // Kitchen not available yet
                } finally {
                    // Regardless of success or failure, mark the request as no longer pending.
                    setRequestPending(false);
                }
            }
        };

    const getRemainingSecond = (tokenExpiry) => {
        // Calculate the remaining time in milliseconds
        const remainingTime = tokenExpiry - Date.now();

        if (remainingTime > 0) {
            // Convert the remaining time to minutes and seconds
            const remainingMinutes = Math.floor(remainingTime / (60 * 1000));
            return remainingMinutes * 1000;
        }
    };

    /* The code block you provided is responsible for running the logon request on application startup. It
    checks for certain conditions and then executes the logon request accordingly. */
    /**
     * Conditions:
     *  Each time when application loads run the logon request
     *  If token is expired run the logon request.
     *  If 24 Hours has passed then run the logon request.
     */
// const HOURS_24 = 1000 * 60 * 24;
// let id = useRef();

// useEffect(() => {
//   if (not(isTransectionUnderProcess())) {
//     if (tokenExpiry > Date.now()) {
//       const remainigSeconds = getRemainingSecond(tokenExpiry);
//       clearTimeout(id.current);
//       id.current = setTimeout(() => {
//         checkPOSStatus();
//         handleLogonRequest();
//       }, remainigSeconds);
//     }
//     setTimeout(() => {
//       checkPOSStatus();
//       handleLogonRequest();
//     }, HOURS_24);
//   }
// }, [token, tokenExpiry]);

// useEffect(() => {
//   if (secret && not(isTransectionUnderProcess())) {
//     handleLogonRequest();
//   }
// }, [secret]);

    /** Restore the transctions history each time when app turned on.
     * Because maybe in some cases app can be restart or restarted
     */
    const {setTransections} = useAdminTransection();
    useEffect(() => {
        const transections = localStorage.getItem("transections");
        if (transections) {
            setTransections(JSON.parse(transections || "{}"));
        }
    }, []);

    const {handleNavigate} = useNavigation();

// Save the query param to globalState is this dev or not.
    useEffect(() => {
        // Read the query parameter from the URL
        const queryParams = new URLSearchParams(location.search);
        if (!globalState?.isDev) {
            setGlobalState((prev) => ({
                ...prev, isDev: queryParams.get("dev") === "true",
            }));
        }
    }, [location.search]);

    /********************************/
// Check on the app startup is any kitchen live or not.
    const {checkIsLiveAnyLocation} = useAdmin();
    const isKitchenLive = async () => {
        const isLive = checkIsLiveAnyLocation();
        if (!isLive) {
            setTransactionStarted(true); // This state is responsible for handling the overlay.
            navigate("/order_incomplete");
        } else {
            setTransactionStarted(false); // This state is responsible for handling the overlay
        }
    };
    useEffect(() => {
        isKitchenLive();
    }, []);


    useEffect(() => {
        console.log('...pipeline is working...')
        console.log('testing')
        console.log(';tess')
    }, []);


    // CLEAR THE COUNTDOWN IF THE PATH IS ROOT.
    useEffect(() => {
        if (location.pathname === '/' && localStorage.getItem('timeoutID')) {
            clearTimeout(Number(localStorage.getItem('timeoutID')));
            localStorage.removeItem('timeoutID');
        }
    }, [location.pathname]);


    /*********************************/
    return (<div className="App">
        {transactionStarted && <Overlay onClick={handleNavigate}/>}
        <ToastContainer/>
        <CountDown/>
        {/*{landing && location.pathname?.split("/")?.[1] !== "admin" ? (*/}
        {/*    <LandingScreen closeLanding={handelSkipClose}/>) : (<AllRoutes/>)}*/}

        <LandingScreen className={landing && location.pathname?.split("/")?.[1] !== "admin" ? 'show' : 'hide'}
                       closeLanding={handelSkipClose}/>
        <AllRoutes />

    </div>);
}

export default App;
