import './Warning.css';
import React from "react";
const Warning = () => {
    return (
        <div style={{padding: 10}}>
            <div style={{
                textAlign: 'center',
                border: '2px solid #fcd34d',
                backgroundColor: '#fffbeb',
                padding: '16px',
                paddingLeft: 20,
                paddingRight: 20,
                borderRadius: '6px'
            }}>
                <h1 style={{

                    fontWeight: 'bold',
                    color: '#b91c1c',
                    marginBottom: '16px'
                }}>
                    ⚠️ Warning
                </h1>
                <h4 style={{
                    lineHeight: '1.6',
                    color: '#333'
                }}>
                    If a QR code <span style={{
                    fontWeight: 'bold',
                    color: '#b91c1c',
                    textDecoration: 'underline'
                }}>does not</span> display after payment, your order was <span style={{
                    fontWeight: 'bold',
                    color: '#b91c1c',
                    textDecoration: 'underline'
                }}>unsuccessful</span>. Please ask a staff member for assistance.
                </h4>
            </div>
        </div>
    )
}

export default Warning;